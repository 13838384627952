import { Decimal } from 'decimal.js'
export function formdata(value, startdef = 6, enddef = 4) {
    if (value) {
        const start = value.slice(0, startdef);
        const end = value.slice(-enddef);
        return `${start}******${end}`;
    }
}
/**
 * 格式化日期
 * @prama t 时间戳
 * @return str MM-dd HH:mm
 */
/* eslint-disable */
export function formatDate(t) {
    t = t || Date.now()
    const time = new Date(t)
    let str = time.getMonth() < 9 ? '0' + (time.getMonth() + 1) : time.getMonth() + 1
    str += '-'
    str += time.getDate() < 10 ? '0' + time.getDate() : time.getDate()
    str += ' '
    str += time.getHours()
    str += ':'
    str += time.getMinutes() < 10 ? '0' + time.getMinutes() : time.getMinutes()
    return str
}
export function toFixedsNum(value, num = 2) {
    value = Number(value);
    num = Number(num);
    if (value > 0) {
        var m = Math.floor(value * (10 ** num)) / (10 ** num);
        return parseFloat(m.toFixed(num));
    } else {
        value = 0;
        return parseFloat(value.toFixed(num));
    }
}
// 加
export function jia(x, y) {
    if (!x) {
        x = 0
    }
    if (!y) {
        y = 0
    }
    const xx = new Decimal(x)
    const yy = new Decimal(y)
    return xx.plus(yy).toNumber()
}

// 减
export function jian(x, y) {
    if (!x) {
        x = 0
    }
    if (!y) {
        y = 0
    }
    const xx = new Decimal(x)
    const yy = new Decimal(y)
    return xx.minus(yy).toNumber()
}

// 乘
export function cheng(x, y) {
    if (!x) {
        x = 0
    }
    if (!y) {
        y = 0
    }
    const xx = new Decimal(x)
    const yy = new Decimal(y)
    return xx.mul(yy).toNumber()
}

// 除
export function chu(x, y) {
    if (!x) {
        x = 0
    }
    if (!y) {
        y = 0
    }
    const xx = new Decimal(x)
    const yy = new Decimal(y)
    return xx.div(yy).toNumber()
}
//时间后一天
export function dataTime() {
    let dateTime = new Date();
    dateTime = dateTime.setDate(dateTime.getDate() + 1);
    //date为时间戳
    var date = new Date(dateTime);
    var Y = date.getFullYear() + '.';
    var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '.';
    var D = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate());
    // var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
    // var m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
    // var s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
    // return Y + M + D + " " + h + m + s;
    return Y + M + D;
}
//时间当天
export function dataTime1() {
    let dateTime = new Date();
    dateTime = dateTime.setDate(dateTime.getDate());
    //date为时间戳
    var date = new Date(dateTime);
    var Y = date.getFullYear() + '.';
    var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '.';
    var D = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate());
    // var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
    // var m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
    // var s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
    // return Y + M + D + " " + h + m + s;
    return Y + M + D;
}

export function accountsChanged() {
    window.ethereum.on("accountsChanged", async function (accounts) {
        console.log(accounts[0]);
        if (accounts[0]) {
            return true
        } else {
            return false
        }
    });

}
//获取当月时间
export function dataNY() {
    let dateTime = new Date();
    dateTime = dateTime.setDate(dateTime.getDate());
    //date为时间戳
    var date = new Date(dateTime);
    var Y = date.getFullYear() + '-';
    var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1);

    return Y + M;
}
