import { createI18n } from 'vue-i18n'
import store from '@/store'

store.state.address = sessionStorage.getItem('address')
store.state.lang = sessionStorage.getItem('lang')

const i18n = createI18n({
    locale: store.state.lang || 'zh-CN',   //从localStorage里获取用户中英文选择，没有则默认中文
    messages: {
        'zh-CN': require('./lang/zh'),
        'en': require('./lang/en'),
        'zh-TW': require('./lang/tw'),
    }
})

export {
    i18n
}