<template>
    <div id="line" style="width: 100%; height: 100%;">
    </div>
</template>

<script>
import * as echarts from 'echarts';
 export default {
    props:{
        lineList:{
            type:Array
        }
    },
   components: {
   },
   data () {
     return {

     }
   },
   watch:{
    lineList(val){
        this.initFuction(val)
    }
   },

   methods: {
        initFuction(val){
            this.myChart = echarts.init(document.getElementById('line'));
            this.myChart.setOption({
            grid: {
                top: "1",
                bottom: "10%",
            },
            xAxis: {
                type: 'category',
                boundaryGap: false,
                axisLine:{  
                    show:false
                },
                splitLine:{show:false},
                axisLabel:{ show: false },
                axisTick: {		//x轴刻度线
                    show:false
                },
            },
            yAxis: {
                type: 'value',
                boundaryGap: [0, '100%'],
                axisLine:{  
                    show:false
                },
                splitLine:{show:false},
                axisLabel:{ show: false },
                axisTick: {		//x轴刻度线
                    show:false
                },
            },

            series: [
                {
                name: 'Fake Data',
                type: 'line',
                symbol: 'none',
                itemStyle: {
                    color: 'rgb(255, 188, 103)'
                },
                areaStyle: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                        offset: 0,
                        color: 'rgb(255, 188, 103)'
                    },
                    {
                        offset: 1,
                        color: 'rgb(255,255,255,0)'
                    }
                    ])
                },
                data: val
                }
            ]
            })
            window.addEventListener("resize", () => {
            this.myChart.resize();

      });
        }
   },
   beforeUnmount() {
      window.removeEventListener("resize", () => {
        this.myChart.resize();
      });
  },
 }
</script>

<style scoped lang='scss'>
</style>