module.exports = {
    language: {
        zh: '中文',
        en: '英語',
        tw: '中文繁體',
        wallet: '連接錢包',
        rate: '幣種匯率',
        total: '總收益',
        balance: '可用餘額',
        rewards: '理財獎勵',
        incentive: '推廣獎勵',
        Community: '社區獎勵',
        Withdraw: '提幣',
        Manage: '理財',
        invest: '投資',
        team: '團隊',
        link: '點擊跳連結',
        copy: '點擊複製地址',
        balances: '餘額',
        multiple: '選擇倍數',
        times: '倍',
        amount: '輸入金額',
        the: '請輸入金額',
        summarize: '概述',
        settlement: '結算方式',
        daily: '每日結算',
        ratio: '每日收益比例',
        earnings: '預估每日收益',
        revenue: '預估收益總額',
        firstearningsreleasetime: '首次收益發放時間',
        accredit: '授權',
        parent: '綁定上級',
        parentaddress: '請輸入上層位址',
        parenaddress: '上層位址',
        node: '社區有效節點',
        hierarchy: '收益層級',


        mydirect: '我的直推',
        time: '時間',
        nodata: '暫無數據',

        announcement: '公告',
        info: '詳情',

        assetslog: '資產日誌',
        invitefriends: '邀請好友',
        investmentrecord: '投資記錄',
        inprogress: '進行中',
        finished: '已完成',
        investmentamount: '投資金額',
        togainrevenue: '已獲取收益',
        estimatedresidualearnings: '預計剩餘收益',
        Confirm: '確認',
        Cancel: '取消',
        withdrawalrecord: '提幣記錄',
        pending: '待處理',
        success: '成功',
        failed: '失敗',
        availablequantity: '可用數量',
        commissionrate: '手續費',
        withdrawalamount: '提幣數量',
        pleaseentertheamountofwithdrawal: '請輸入提幣數量',
        withdrawaladdress: '提幣位址',
        extract: '提取',

        replication: '位址複製成功',
        than: '填寫數量要大於',
        withdrawal: '提幣成功',
        预估周期: '預估週期(天)',
        be: '授權數量必須為',
        among: '之間',
        transaction: '交易已發起 等待確認中',
        complete: '交易完成',
        cancela: '取消交易',
        insufficient: '餘額不足',
        exceeded: '授權金額已超出',
        enter: '請輸入有效位址',
        valid: '請輸入有效金額',
        currency: '提幣',
        more: '没有更多了',
        none: '暫無',
        plswallet: '請連接錢包',

        请求超时: '請求超時',
        网络错误: '網絡錯誤',


        等级: "等級",
        所需小区数量: "小區數量",
        个: "個",
        收益比例: "收益比例",
        客服: "客服",
        查看更多: "查看更多",
        发送: "發送",
        断开连接: "斷開連接",
        连接失败: "连接失败",
        签到: "簽到",
        签到日志: "簽到日誌",
        已连续签到: "已連續簽到",
        天: "天",
        点击签到: "點擊簽到",
        签到规则: "簽到規則",
        签到成功: "簽到成功",
        每日签到送: `每日簽到獎勵：使用者每天簽到可獲得{tokensName}獎勵。`,
        每次可随机获得: "隨機獎勵金額：每次簽到可隨機獲得",
        签到时必须有投资中的订单: "簽到條件：用戶必須有正在進行的投資訂單才能簽到。",
        连续签到可获得更多的: `連續簽到獎勵：連續簽到可獲得更多的{tokensName}。`,
        中断后可继续签到: "中斷後可繼續：即使簽到中斷，使用者仍可繼續簽到。",

        状态: '狀態',
        认购时间: '認購時間',
        每日收益: '每日收益(預估)',
        累计收益: '累計收益',
        到期时间: '預計到期',
        复投: '複投',
        投资中: '投資中',
        已完成: '已結束',
        投资金额: '投資金額',
        投资详情: '投資詳情',
        收益明细: '收益明細',
        复投成功: '複投成功',
        取消复投成功: '取消複投成功',
    },

}