import {
    createStore
} from 'vuex'

export default createStore({
    state: {
        pagenum: 1,
        address: '',
        lang: sessionStorage.getItem("lang") || 'en'
    },
    getters: {},
    mutations: {
        editpagenum(state, payload) {
            // console.log(payload)
            state.pagenum = payload
        },
    },
    actions: {},
    modules: {}
})