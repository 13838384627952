<template>
  <div class="footer">
    <div @click="fun(1)" >
		<img src="../assets/imgs/icon11.png" alt="" v-if="img1">
		<img src="../assets/imgs/icon21.png" alt="" v-else>
		<span :class="img1?'color1':''">{{$t('language.Manage')}}</span>
	</div>
    <div @click="fun(2)">
		<img src="../assets/imgs/icon22.png" alt="" v-if="img2">
		<img src="../assets/imgs/icon12.png" alt="" v-else>
		<span :class="img2?'color1':''">{{$t('language.invest')}}</span>
	</div>
	<div @click="fun(3)">
		<img src="../assets/imgs/icon23.png" alt="" v-if="img3">
		<img src="../assets/imgs/icon13.png" alt="" v-else>
		<span :class="img3?'color1':''">{{$t('language.team')}}</span>
	</div>
  </div>
</template>

<script>
export default {
  data() {
  	return {
  		img1:false,
		img2:false,
		img3:false,
  	}
  },
  created() {
    this.$route.path=='/Investment'?
	this.fun(2):this.$route.path=='/TeamView'?
	this.fun(3):this.fun(1)
	if(this.$store.state.pagenum==1){
		this.img1=true
	}else if(this.$store.state.pagenum==2){
		this.img2=true
	}else if(this.$store.state.pagenum==3){
		this.img3=true
	}
    let address = sessionStorage.getItem('address')
    if(!address) {
            this.$store.state.pagenum = 1
            this.img1=true
			this.img2=false
			this.img3=false
          }
  },
  methods:{
	fun(num){
        let address = sessionStorage.getItem('address')
      
	    if(num==1){
	        this.img1=true
			this.img2=false
			this.img3=false
			this.$store.commit("editpagenum",num)
			this.$router.push("/")
		}else if(num==2){
			  this.img1=false
			  this.img2=true
			  this.img3=false
			  this.$store.commit("editpagenum",num)
			  this.$router.push("/Investment")
		  }else if(num==3){
			  this.img1=false
			  this.img2=false
			  this.img3=true
			  this.$store.commit("editpagenum",num)
			  this.$router.push("/TeamView")
		  }

          if(!address) {
            this.$store.state.pagenum = 1
            this.img1=true
			this.img2=false
			this.img3=false
          }
	  }
  },
}
</script>

<style scoped lang="scss">
	.color1{
		color: #E9BF85 !important;
	}
	
	.footer>div:nth-child(1)>img{
		width: 0.16rem;
		height: 0.16rem;
	}
	
	.footer>div:nth-child(2)>img{
		width: 0.16rem;
		height: 0.16rem;
	}
	
	.footer>div:nth-child(3)>img{
		width: 0.18rem;
		height: 0.16rem;
	}
	
	.footer{
		width: 100%;
		height: 0.49rem;
		background-color: #2D2D2F;
		position: fixed;
		bottom: 0;
		padding-bottom: env(safe-area-inset-bottom);
		display: flex;
		align-items: center;
		justify-content: space-around;
		div{
			height: 0.34rem;
			flex: 1;
			// background-color: skyblue;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: center;
			margin-top: 0.02rem;
			span{
				font-size: 0.1rem;
				font-family: PingFang SC-Regular, PingFang SC;
				font-weight: 400;
				color: #999999;
			}
		}
	}
</style>
