import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import { showToast } from 'vant';
import store from '@/store'
import { i18n } from "@/utils/i18n";
const $t = i18n.global.t
const routes = [
    {
        path: '/',
        name: 'home',//理财
        component: HomeView
    },
    {
        path: '/Investment',
        name: 'Investment',
        component: () => import('../views/AboutView.vue'),//投资
        meta: { requiresAddress: true }
    },
    {
        path: '/InvestmentRecord',
        name: 'InvestmentRecord',
        component: () => import('../views/InvestmentRecord.vue'),//投资记录,
        meta: { requiresAddress: true }
    },
    {
        path: '/TeamView',
        name: 'TeamView',
        component: () => import('../views/TeamView.vue'),//团队
        meta: { requiresAddress: true }
    },
    {
        path: '/Assetlog',
        name: 'Assetlog',
        component: () => import('../views/Assetlog.vue'),//资产日志
        meta: { requiresAddress: true }
    },
    {
        path: '/AnnounceMent',
        name: 'AnnounceMent',
        component: () => import('../views/AnnounceMent.vue'),//公告列表
        meta: { requiresAddress: true }
    },
    {
        path: '/AnnouncementDetails',
        name: 'AnnouncementDetails',
        component: () => import('../views/AnnouncementDetails.vue'),//公告详情
        meta: { requiresAddress: true }
    },
    {
        path: '/WithdrawCurrency',
        name: 'WithdrawCurrency',
        component: () => import('../views/WithdrawCurrency.vue'),//提币
        meta: { requiresAddress: true }
    },
    {
        path: '/WithdrawalRecord',
        name: 'WithdrawalRecord',
        component: () => import('../views/WithdrawalRecord.vue'),//提币记录
        meta: { requiresAddress: true }
    },
    {
        path: '/ChatView',
        name: 'ChatView',
        component: () => import('../views/ChatView.vue'),//提币记录
        meta: { requiresAddress: true }
    },
    {
        path: '/Calendar',
        name: 'Calendar',
        component: () => import('../views/Calendar.vue'),//签到
        meta: { requiresAddress: true }
    },
    {
        path: '/InvestmentDetails',
        name: 'InvestmentDetails',
        component: () => import('../views/InvestmentDetails.vue'),//投资详情
        meta: { requiresAddress: true }
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

// router.beforeEach((to, form, next) => {
//     console.log(store.state.address);
//     if (store.state.address) { 
//         next() 
//     }
//     else {
//         router.push('/')
//     }
// })
router.beforeEach((to, from, next) => {
    // 检查路由的元数据字段
    if (to.meta.requiresAddress) {
        // 假设你的地址信息存储在某个全局状态或服务中
        const hasAddress = store.state.address; // 这个函数用于检查用户是否有地址
        if (!hasAddress) {
            // 如果用户没有地址，可以重定向到其他页面，或者返回登录页
            next('/'); // 这里你可以根据你的路由结构重定向到合适的页面
            showToast({
                message: $t('language.plswallet'),
                position: 'bottom',
            });
        }
    }

    // 如果用户有地址或者不需要地址，继续路由导航
    next();
});


export default router
