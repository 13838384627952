import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { i18n } from '@/utils/i18n'
import store from './store'
import GoEasy from 'goeasy';
import Vant from 'vant';
import 'vant/lib/index.css';

const goEasy = GoEasy.getInstance({
    host: 'hangzhou.goeasy.io',	//应用所在的区域地址: 【hangzhou.goeasy.io |singapore.goeasy.io】
    // appkey: 'BC-c998bdb6c792452aba526745d6009c5d', // common key,
    appkey: 'BC-bc04d1e0ce984c1784ea76b261403e5a', // common key,
    modules: ['im']
});

const app = createApp(App)
app.provide('goEasy', goEasy)
app.use(store).use(router).use(i18n).use(Vant).mount('#app')


