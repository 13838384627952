// http.js封装axios的请求方式(get请求、post请求) 
// 封装axios超时请求时间
// token设置

import axios from 'axios' // 引用axios
import { showToast, showLoadingToast, closeToast } from 'vant';
import store from '@/store'
import md5 from 'md5';
import randomString from "@/utils/random"
import { i18n } from "@/utils/i18n";
const $t = i18n.global.t

// axios 配置
axios.defaults.timeout = 120000 // 设置接口响应时间
axios.defaults.baseURL = process.env.NODE_ENV === 'development' ? '/api' : process.env.VUE_APP_NODE_ENV // 这是调用数据接口,公共接口url+调用接口名
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

axios.interceptors.request.use(
    config => {
        let address = store.state.address
        let lang = store.state.lang
        let randStr = randomString(32);
        let dataStr = address + randStr
        let sign = md5(md5(dataStr.toLowerCase()) + 'J1aF1LIf0WdFigTw')
        if (
            // !config.headers.hasOwnProperty('address') &&
            address) {
            config.headers.address = address
        }
        if (
            // !config.headers.hasOwnProperty('randStr') &&
            randStr) {
            config.headers.randStr = randStr
        }
        if (
            // !config.headers.hasOwnProperty('sign') &&
            sign) {
            config.headers.sign = sign
        }
        if (
            // !config.headers.hasOwnProperty('lang') &&
            lang) {
            config.headers.lang = lang
        }
        return config
    },
    function (error) {
        // 对请求错误做些什么
        return Promise.reject(error)
    }
)

// 封装get请求
export function get(url, params = {}, isloading = true) {
    if (isloading) {
        showLoadingToast({
            message: '',
            forbidClick: true,
            duration: 0,
            iconSize: '60px',
        });
    }
    return new Promise((resolve) => {
        axios
            .get(url, {
                params: params
            })
            .then(response => {
                closeToast()
                if (response.data.code == 401) {
                    showToast({
                        message: response.data.msg,
                        position: 'bottom',
                    });
                }
                resolve(response.data)
            })
            .catch(err => {
                if (err.code === 'ECONNABORTED') {
                    showToast({
                        message: $t('language.请求超时'),
                        position: 'bottom',
                    });
                } else {
                    showToast({
                        message: $t('language.网络错误'),
                        position: 'bottom',
                    });
                    // reject(err)
                }
            })
    })
}
// 封装post请求
export function post(url, data = {}, isloading = true) {
    if (isloading) {
        showLoadingToast({
            message: '',
            forbidClick: true,
            duration: 0,
            iconSize: '60px',
        });
    }
    return new Promise((resolve) => {
        axios.post(url, data).then(
            response => {
                closeToast()
                if (response.data.code == 401) {
                    showToast({
                        message: response.data.msg,
                        position: 'bottom',
                    });
                }
                resolve(response.data)
            },
            err => {
                if (err.code === 'ECONNABORTED') {

                    showToast({
                        message: $t('language.请求超时'),
                        position: 'bottom',
                    });
                } else {
                    showToast({
                        message: $t('language.网络错误'),
                        position: 'bottom',
                    });
                    // reject(err)
                }
            }
        )
    })
}