<template>
	<div class="home">
		<!-- <div v-if="homeLoading" class="homeLoading">
            <van-loading class="van_loading" type="spinner" />
        </div> -->
		<div class="header">
			<img src="../assets/imgs/header1.png" alt="">
			<div class="header-right">
				<div v-if="isName">
					<img src="../assets/imgs/icon1.png" alt="">
					<span @click="createdFunciton">{{ $t('language.wallet') }}</span>
				</div>
				<div v-else class="header_name">
					{{ formdata(username) }}
				</div>
				<div @click="editLanguage">
					<img src="../assets/imgs/icon2.png" alt="">
				</div>
			</div>
		</div>

		<div id="swipercom">
			<div class="swiper-container" id="swiperIndex">
				<div class="swiper-wrapper" style="width: 100%;height: 1.62rem;">
					<div style="height: 1.62rem;" class="swiper-slide" v-for="(item, i) in imgs" :key="i">
						<img :src="item.image" alt="">
					</div>
				</div>
			</div>
		</div>

		<div class="announcement" @click="goAnnounceMent">
			<van-notice-bar class="announcement_span" :left-icon="require('../assets/imgs/icon3.png')"
				:text="announcement" />
		</div>

		<div class="Friend">
			<div @click="open">
				<img src="../assets/imgs/icon5.png" alt="">
				<div>
					<span>PancakeSwap</span>
					<div>
						<span>{{ $t('language.link') }}</span>
						<img src="../assets/imgs/icon7.png" alt="">
					</div>
				</div>
			</div>
			<div @click="copy">
				<img src="../assets/imgs/icon6.png" alt="">
				<div>
					<span>{{ $t('language.invitefriends') }}</span>
					<div id="btn">
						<span>{{ $t('language.copy') }}</span>
						<img src="../assets/imgs/icon8.png" alt="">
					</div>
				</div>
			</div>
		</div>

		<div class="calendar">
			<a href="https://freshhappygame.com/" target="_blank" rel="noopener noreferrer">
				<img :src="require('../assets/images/game1.png')" style="width: 100%; height: 100%;" alt="">
			</a>
		</div>

		<div class="Exchangerate">
			<div>
				<span>{{ $t('language.rate') }}</span>
				<p>1{{ tokensName }}≈{{ usdt }}USDT</p>
			</div>
			<div style="height: 0.6rem;">
				<LineEchart :lineList="lineList"></LineEchart>
			</div>
		</div>


		<div class="Totalrevenue">
			<div class="Totalrevenue-top">
				<div>
					<span>{{ $t('language.total') }}({{ tokensName }})</span>
					<p>{{ total }}</p>
				</div>
				<div></div>
				<div>
					<span>{{ $t('language.balance') }}({{ tokensName }})</span>
					<p>{{ balance }}</p>
				</div>
			</div>
			<div class="Totalrevenue-btm">
				<div>
					<div @click="goAssetlog(3)">
						<p>{{ $t('language.rewards') }}</p>
						<img src="../assets/imgs/icon10.png" alt="">
					</div>
					<span>{{ invest }}</span>
				</div>
				<div></div>
				<div>
					<div @click="goAssetlog(1)">
						<p>{{ $t('language.incentive') }}</p>
						<img src="../assets/imgs/icon10.png" alt="">
					</div>
					<span>{{ push }}</span>
				</div>
				<div></div>
				<div>
					<div @click="goAssetlog(2)">
						<p>{{ $t('language.Community') }}</p>
						<img src="../assets/imgs/icon10.png" alt="">
					</div>
					<span>{{ dict }}</span>
				</div>
			</div>
		</div>

		<div class="Withdrawcurrency" @click="goWithdrawCurrency">
			{{ $t('language.Withdraw') }}
		</div>


		<van-popup class="Language" v-model:show="isLanguage" @click='closepopup'>
			<div>
				<div @click="ToggleLanguage(1)">
					<p>English</p>
					<img src="../assets/imgs/icon15.png" alt="" v-if="Language1">
					<img src="../assets/imgs/icon14.png" alt="" v-else>
				</div>
				<div></div>
				<div @click="ToggleLanguage(2)">
					<p>简体中文</p>
					<img src="../assets/imgs/icon15.png" alt="" v-if="Language2">
					<img src="../assets/imgs/icon14.png" alt="" v-else>
				</div>
				<div></div>
				<div @click="ToggleLanguage(3)">
					<p>繁體中文</p>
					<img src="../assets/imgs/icon15.png" alt="" v-if="Language3">
					<img src="../assets/imgs/icon14.png" alt="" v-else>
				</div>
			</div>
		</van-popup>
		<div class="gameLink_box">
			<a href="https://freshhappygame.com/" target="_blank" rel="noopener noreferrer">
				<img :src="require(`../assets/images/game.png`)" style="width: 100%; height: 100%;" alt="">
			</a>
		</div>
		<Footer></Footer>

	</div>
</template>

<script>
import { showToast, Locale } from 'vant';
import Home from '@/http/index'
import en from 'vant/es/locale/lang/en-US';
import zh from 'vant/es/locale/lang/zh-CN';
import tw from 'vant/es/locale/lang/zh-TW';
import 'swiper/css/swiper.css' //引入swiper样式
import Swiper from 'swiper'; //引入swiper
import Footer from "../components/Footer.vue"
import { formdata, toFixedsNum } from '@/utils/index'
import LineEchart from '../components/LineEchart.vue'
import Web3 from 'web3'
export default {
	inject: ['reload'],
	components: { Footer, LineEchart },

	data() {
		return {
			imgs: [],//banner
			announcement: '',//公告
			isLanguage: false,
			Language1: false,
			Language2: true,
			Language3: false,
			username: '',//地址
			isName: true,
			total: 0, //总收益
			balance: 0,//余额
			invest: 0,// 投资收益
			dict: 0,// 社区奖励
			push: 0,// 推广奖励
			usdt: 0,//汇率
			lineList: [],//
			homeLoading: true, //loading
			mySwiper: null,
			tokensName: '',
			lang: 'zh-CN',

		}
	},

	mounted() {
		this.mySwiper = new Swiper('#swiperIndex', {
			slidesPerView: 1.2,
			// spaceBetween: 30,
			centeredSlides: true,
			loop: true,
			autoplay: {
				delay: 3000,//3秒切换一次
				disableOnInteraction: false
			},
			pagination: {
				el: '.swiper-pagination',
				clickable: true,
			},
			observer: true,
			observeParents: true,
			mousewheel: true,
		})
	},
	computed: {
		formdata: () => formdata
	},
	methods: {
		copy() {
			if (!this.$store.state.address) {
				return showToast({
					message: this.$t('language.plswallet'),
					position: 'bottom',
				});
			}
			const btn = document.querySelector('#btn');
			btn.addEventListener('click', () => {
				const input = document.createElement('input');
				input.setAttribute('readonly', 'readonly');
				input.setAttribute('value', this.$store.state.address);
				document.body.appendChild(input);
				input.setSelectionRange(0, 9999);
				input.select();
				document.execCommand('copy');
				showToast({
					message: this.$t('language.replication'),
					position: 'bottom',
				});
				document.body.removeChild(input);
			})

		},
		open() {
			window.open('https://pancakeswap.finance/')
		},
		goWithdrawCurrency() {
			this.$router.push("/WithdrawCurrency")
		},
		gotocalendar() {
			this.$router.push("/Calendar")
		},
		goAnnounceMent() {
			this.$router.push("/AnnounceMent")
		},
		goAssetlog(id) {
			this.$router.push(`/Assetlog?id=${id}`)

		},
		ToggleLanguage(num) {
			if (num == 1) {
				this.$i18n.locale = 'en'
				this.$store.state.lang = this.$i18n.locale
				Locale.use('en-US', en)
				sessionStorage.setItem('lang', 'en')
				this.Language1 = true
				this.Language2 = false
				this.Language3 = false
				this.reload()
			} else if (num == 2) {
				this.$i18n.locale = 'zh-CN'
				this.$store.state.lang = this.$i18n.locale
				sessionStorage.setItem('lang', 'zh-CN')
				Locale.use('zh-CN', zh)
				this.Language1 = false
				this.Language2 = true
				this.Language3 = false
				this.reload()
			} else if (num == 3) {
				this.$i18n.locale = 'zh-TW'
				this.$store.state.lang = this.$i18n.locale
				sessionStorage.setItem('lang', 'zh-TW')
				Locale.use('zh-TW', tw)
				this.Language1 = false
				this.Language2 = false
				this.Language3 = true
				this.reload()
			}
			this.editLanguage()
		},
		editLanguage() {
			this.isLanguage = !this.isLanguage

		},
		closepopup() {
			this.isLanguage = false
		},
		//链接钱包
		async createdFunciton() {
			if (window.ethereum) {
				const web3 = new Web3(window.ethereum)
				try {
					//   const accounts = await window.ethereum.request({ methods: 'eth_requestAccounts' })
					const accounts = await window.ethereum.enable()
					const froms = accounts[0]
					const message = 'Login to Spectre'
					await web3.eth.personal.sign(message, froms)
					this.username = froms
					this.$store.state.address = froms
					sessionStorage.setItem('address', froms)
					this.isName = false
					this.getManageFunciton()
				} catch (error) {
					this.$store.state.address = ''
					sessionStorage.setItem('address', '')
					this.reload()
					this.isName = true
				}
			}
		},
		//banner
		bannerFunciton() {
			Home.home().then(res => {
				this.lineList = []
				this.mySwiper && this.mySwiper.destroy(true);
				this.imgs = res.data.slide || []
				this.usdt = res.data.price || 0 //汇率
				this.announcement = res.data.notice?.title || ''
				res.data.kline.length && res.data.kline.forEach(e => {
					this.lineList.push(e.price)
				});
				this.$nextTick(() => {
					this.mySwiper = new Swiper('#swiperIndex', {
						slidesPerView: 1.2,
						// spaceBetween: 30,
						centeredSlides: true,
						loop: true,
						autoplay: {
							delay: 3000,//3秒切换一次
							disableOnInteraction: false
						},
						pagination: {
							el: '.swiper-pagination',
							clickable: true,
						},
						observer: true,
						observeParents: true,
						mousewheel: true,
					})
				})
			})
		},
		//理财信息
		getManageFunciton() {
			Home.getManage().then(res => {
				if (res.code == 200) {
					this.total = toFixedsNum(res.data.total) || 0 //总收益
					this.balance = toFixedsNum(res.data.balance) || 0 //余额
					this.invest = toFixedsNum(res.data.invest) || 0 // 投资收益
					this.dict = toFixedsNum(res.data.dict) || 0 // 社区奖励
					this.push = toFixedsNum(res.data.push) || 0 // 推广奖励
				}

			})
		},
		currencyacquisition() {
			Home.currency().then(res => {
				if (res.code == 200) {
					this.tokensName = res.data['Tokens-name']
				}
			})
		}

	},
	created() {
		this.currencyacquisition()
		this.bannerFunciton()
		if (sessionStorage.getItem('address')) {
			this.getManageFunciton()
			this.isName = false
			this.username = sessionStorage.getItem('address')
		}
		this.$i18n.locale = sessionStorage.getItem('lang')
		this.$store.state.lang = sessionStorage.getItem('lang')
		this.lang = this.$store.state.lang || 'en'
		switch (this.$store.state.lang) {
			case 'en':
				this.Language2 = false
				this.Language1 = true
				this.Language3 = false

				break;
			case 'zh-CN':
				this.Language2 = true
				this.Language1 = false
				this.Language3 = false

				break;
			case 'zh-TW':
				this.Language2 = false
				this.Language1 = false
				this.Language3 = true
				break;

			default:
				break;
		}



	}
}
</script>
<style scoped>
.gameLink_box{
	width: 100%;
	height: 20vw;
	position: fixed;
	left: 0;
	bottom: 0.49rem;
}
.announcement_span {
	width: 100%;
	color: #fff;
	background: #3D3D3F;
	border-radius: 0.1rem 0.1rem 0.1rem 0.1rem;
}

:deep(.van-badge__wrapper) {
	color: #E9BB80;
}

:deep(.van-popup--center) {
	max-width: 100% !important;
}

.my-swipe .van-swipe-item {
	color: #fff;
	font-size: 20px;
	line-height: 150px;
	text-align: center;
}

.homeLoading {
	width: 100%;
	min-height: 100vh;
	/* height: 100vh; */
}

.van_loading {
	width: 40px;
	line-height: 100vh;
	margin: auto;
}

.header_name {
	/* width: 0.87rem; */
	padding: 0 0.1rem;
	font-size: 0.1rem;
	overflow: hidden;
}

.Language>div>div:nth-child(odd) {
	width: 2.47rem;
	height: 0.2rem;
	/* background-color: skyblue; */
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.Language>div>div:nth-child(odd)>p {
	font-size: 14px;
	font-family: PingFang SC-Regular, PingFang SC;
	font-weight: 400;
	color: #999999;
}

.Language>div>div:nth-child(odd)>img {
	width: 0.18rem;
	height: 0.18rem;
}

.Language>div>div:nth-child(even) {
	width: 100%;
	height: 0.01rem;
	background-color: #535357;
}

.Language>div {
	width: 2.92rem;
	height: 1.6rem;
	background: #3D3D3F;
	box-shadow: 0px 0px 10px 1px rgba(244, 220, 177, 0.1);
	border-radius: 0.1rem 0.1rem 0.1rem 0.1rem;
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	opacity: 1;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: space-evenly;
}

.Language {
	position: fixed;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 999;
}

.Withdrawcurrency {
	width: 3.44rem;
	height: 0.52rem;
	background: linear-gradient(90deg, #EDD6A0 0%, #E9BB80 100%);
	border-radius: 0.1rem 0.1rem 0.1rem 0.1rem;
	display: flex;
	align-items: center;
	justify-content: space-around;
	font-size: 0.14rem;
	font-family: PingFang SC-Regular, PingFang SC;
	font-weight: 400;
	color: #333333;
	margin-bottom: calc(0.89rem + env(safe-area-inset-bottom) + 20vw);
}

.Totalrevenue-btm>div:nth-child(odd)>div {
	display: flex;
	align-items: center;
	font-size: 0.12rem;
	font-family: PingFang SC-Regular, PingFang SC;
	font-weight: 400;
	color: #D0C5B5;
}

.Totalrevenue-btm>div:nth-child(odd)>div>img {
	width: 0.04rem;
	height: 0.08rem;
	margin-left: 0.03rem;
	margin-top: 0.02rem;
}

.Totalrevenue-btm>div:nth-child(odd)>span {
	font-size: 0.18rem;
	font-family: PingFang SC-Regular, PingFang SC;
	font-weight: 400;
	color: #EDD6A0;

}

.Totalrevenue-btm>div:nth-child(odd) {
	min-width: 0.55rem;
	max-width: 0.8rem;
	/* width: 0.6rem; */
	height: 100%;
	/* background-color: skyblue; */
	display: flex;

	flex-direction: column;
	justify-content: space-between;

}

.Totalrevenue-btm>div:nth-child(even) {
	width: 0.01rem;
	height: 0.14rem;
	background-color: #49494D;
}

.Totalrevenue-btm {
	width: 3.1rem;
	height: 0.46rem;
	/* background-color: skyblue; */
	margin-top: 0.15rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
}


.Totalrevenue-top>div:nth-child(1) {
	width: 1.44rem;
	height: 0.58rem;
	/* background-color: skyblue; */
	display: flex;
	justify-content: space-between;
	flex-direction: column;
}

.Totalrevenue-top>div:nth-child(1)>span {
	font-size: 0.12rem;
	font-family: PingFang SC-Regular, PingFang SC;
	color: #D0C5B5;
}

.Totalrevenue-top>div:nth-child(1)>p {
	font-size: 0.26rem;
	font-family: PingFang SC-Semibold, PingFang SC;
	font-weight: 600;
	color: #EDD6A0;
}

.Totalrevenue-top>div:nth-child(2) {
	width: 0.01rem;
	height: 0.23rem;
	background-color: #49494D;


}

.Totalrevenue-top>div:nth-child(3)>span {
	font-size: 0.12rem;
	font-family: PingFang SC-Regular, PingFang SC;
	color: #D0C5B5;
}

.Totalrevenue-top>div:nth-child(3)>p {
	font-size: 0.26rem;
	font-family: PingFang SC-Semibold, PingFang SC;
	font-weight: 600;
	color: #EDD6A0;
}

.Totalrevenue-top>div:nth-child(3) {
	width: 1.44rem;
	height: 0.58rem;
	/* background-color: skyblue; */
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	align-items: flex-end;
}

.Totalrevenue-top {
	width: 3.1rem;
	height: 0.95rem;
	border-bottom: 0.01rem solid #49494D;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.Totalrevenue {
	width: 3.44rem;
	height: 1.81rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 0.2rem;
	background: url("../assets/imgs/back4.png") no-repeat;
	background-size: 100%;
}



.Exchangerate>div>span {
	font-size: 0.12rem;
	font-family: PingFang SC-Regular, PingFang SC;
	color: #D0C5B5;
}

.Exchangerate>div>p {
	font-size: 0.16rem;
	font-family: PingFang SC-Regular, PingFang SC;
	color: #EDD6A0;
	margin-top: 0.08rem;
}

.Exchangerate>div {
	flex: 1;
	margin-left: 0.16rem;
	display: flex;
	flex-direction: column;
}

.Exchangerate>img {
	width: 0.9rem;
	height: 0.41rem;
	margin-right: 0.17rem;
}

.Exchangerate {
	width: 3.44rem;
	height: 0.8rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 0.2rem;
	background: url("../assets/imgs/back3.png") no-repeat;
	background-size: 100%;
}

.calendar {
	width: 3.44rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 0.2rem;
	/* background: url("../assets/images/ch.png") no-repeat;
		background-size: 100%; */
}

.Friend>div:nth-child(1)>img {
	width: 0.29rem;
	height: 0.3rem;
	margin-left: 0.16rem;
	margin-right: 0.1rem;
}

.Friend>div:nth-child(1)>div {
	/* height: 0.3rem; */
	display: flex;
	flex-direction: column;
}

.Friend>div:nth-child(1)>div>span {
	font-size: 0.14rem;
	font-family: PingFang SC-Medium, PingFang SC;
	font-weight: 500;
	color: #0E304E;
	margin-bottom: 0.06rem;
}

.Friend>div:nth-child(1)>div>div {
	height: 0.17rem;
	display: flex;
	align-items: center;
}

.Friend>div:nth-child(1)>div>div>span {
	font-size: 0.12rem;
	font-family: PingFang SC-Regular, PingFang SC;
	color: #173755;
}

.Friend>div:nth-child(1)>div>div>img {
	width: 0.04rem;
	height: 0.06rem;
	margin-top: 0.02rem;
	margin-left: 0.04rem;
}


.Friend>div:nth-child(2)>img {
	width: 0.42rem;
	height: 0.42rem;
	margin-left: 0.16rem;
	margin-right: 0.1rem;
}

.Friend>div:nth-child(2)>div {
	/* height: 0.3rem; */
	display: flex;
	flex-direction: column;
}

.Friend>div:nth-child(2)>div>span {
	font-size: 0.14rem;
	font-family: PingFang SC-Medium, PingFang SC;
	font-weight: 500;
	color: #604E28;
	margin-bottom: 0.06rem;
}

.Friend>div:nth-child(2)>div>div {
	height: 0.17rem;
	display: flex;
	align-items: center;
}

.Friend>div:nth-child(2)>div>div>span {
	font-size: 0.12rem;
	font-family: PingFang SC-Regular, PingFang SC;
	color: #604C27;
}

.Friend>div:nth-child(2)>div>div>img {
	width: 0.09rem;
	height: 0.09rem;
	margin-top: 0.02rem;
	margin-left: 0.04rem;
}

.Friend>div:nth-child(1) {
	width: 1.66rem;
	height: 0.91rem;
	background: url("../assets/imgs/back1.png") no-repeat;
	background-size: 100%;
	border-radius: 0.1rem 0.1rem 0.1rem 0.1rem;
	display: flex;
	align-items: center;
}

.Friend>div:nth-child(2) {
	width: 1.66rem;
	height: 0.91rem;
	background: url("../assets/imgs/back2.png") no-repeat;
	background-size: 100%;
	border-radius: 0.1rem 0.1rem 0.1rem 0.1rem;
	display: flex;
	align-items: center;
}

.Friend {
	width: 3.44rem;
	height: 0.91rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 0.1rem;
}

.announcement>span {
	font-size: 0.14rem;
	font-family: PingFang SC-Regular, PingFang SC;
	color: #EAEBEF;
}

.announcement>img {
	width: 0.17rem;
	height: 0.14rem;
	margin-right: 0.1rem;
	margin-left: 0.15rem;
}

.announcement {
	width: 3.44rem;
	height: 0.44rem;
	background: #3D3D3F;
	border-radius: 0.1rem 0.1rem 0.1rem 0.1rem;
	display: flex;
	align-items: center;
	margin-bottom: 0.2rem;
}





#swipercom {
	/* width: 7.5rem; */
	width: 100%;
	height: 1.62rem;
	margin-top: 0.2rem;
	margin-bottom: 0.2rem;
}

#swipercom>#swiperIndex.swiper-container {
	width: 100%;
	/* height: 5rem; */
	height: 1.62rem;
	border-radius: 0.1rem;
}

#swipercom>#swiperIndex.swiper-container>.swiper-slide img {
	width: 100%;
}

#swipercom>#swiperIndex.swiper-container>.swiper-pagination-bullet-active {
	background-color: orangered;
}

.swiper {
	width: 100%;
	height: 100%;
}

.swiper-slide {
	/* width: 3.22rem !important; */
	height: 1.62rem !important;
	text-align: center;
	font-size: 18px;
	/* background: #fff; */
	display: flex;
	justify-content: center;
	align-items: center;
	transform: scale(0.9);
	border-radius: 0.1rem 0.1rem 0.1rem 0.1rem;
	/* margin-left: 0 !important; */
	/* margin-right: 0 !important; */
}

.swiper-slide img {
	display: block;
	width: 100%;
	height: 100%;
	border-radius: 0.1rem;
	/* object-fit: cover; */
}

.swiper-slide-active {
	transform: scale(1);
}









.header-right>div:nth-child(2)>img {
	width: 0.15rem;
	height: 0.15rem;
}

.header-right>div:nth-child(2) {
	width: 0.34rem;
	height: 0.34rem;
	background: linear-gradient(90deg, #EDD6A0 0%, #E9BB80 100%);
	border-radius: 0.1rem 0.1rem 0.1rem 0.1rem;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 0.1rem;
	margin-right: 0.15rem;
}

.header-right>div:nth-child(1)>img {
	width: 0.12rem;
	height: 0.12rem;
	margin-right: 0.04rem;
}

.header-right>div:nth-child(1)>span {
	font-size: 0.12rem;
	font-family: PingFang SC-Regular, PingFang SC;
	color: #333333;
}

.header-right>div:nth-child(1) {
	width: 0.87rem;
	height: 0.34rem;
	background: linear-gradient(90deg, #EDD6A0 0%, #E9BB80 100%);
	border-radius: 0.1rem 0.1rem 0.1rem 0.1rem;
	display: flex;
	align-items: center;
	justify-content: center;
}

.header-right {
	height: 0.34rem;
	display: flex;
	align-items: center;
}

.header {
	width: 100%;
	height: 0.34rem;
	/* background-color: skyblue; */
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 0.2rem;
}

.header>img {
	width: 0.69rem;
	height: 0.21rem;
	margin-left: 0.27rem;
}

.home {
	width: 100%;
	/* height: 100%; */
	display: flex;
	flex-direction: column;
	align-items: center;
	flex-wrap: nowrap;
	background-color: #1C1C1C;
	position: relative;
	/* height: 100%; */
}
</style>